// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-practice-js": () => import("./../../../src/pages/our-practice.js" /* webpackChunkName: "component---src-pages-our-practice-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-timetable-js": () => import("./../../../src/pages/timetable.js" /* webpackChunkName: "component---src-pages-timetable-js" */),
  "component---src-pages-what-we-offer-js": () => import("./../../../src/pages/what-we-offer.js" /* webpackChunkName: "component---src-pages-what-we-offer-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */)
}

