const colours = {
    black: '#000000',
    white: '#ffffff',
	orange: '#F6A73B',
    bloodOrange: '#DD5E41',
	purple: '#8888BE',

    lightOrange: '#FDEAD1',
    lightYellow: '#F6EFD5',
    lightPurple: '#E0E0F3',
    lightBlue: '#D6E9EB',
    lightGreen: '#f3f4ed',
	lightBeige: '#E7E0DA',
	
	grey: '#6b6c6f',
}

export default colours
